import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

const HowItWorksManager = () => {
  const [formData, setFormData] = useState({
    dealsAndBonusCards: "",
    feedAndStories: "",
    shopping: "",
    delivery: "",
    provider: "",
    flyer: "",
  });
  const [originalData, setOriginalData] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data for the form
  const fetchData = async () => {
    try {
      const response = await axios.get(`${URL}/hiw`);
      if (response.data.length > 0) {
        const latestEntry = response.data[0];
        setFormData(latestEntry);
        setOriginalData(latestEntry); // Keep a copy to detect changes
      }
    } catch (error) {
      showToast("Error fetching data", "error");
    }
  };

  // Show toast
  const showToast = (message, severity) => {
    setToast({ open: true, message, severity });
  };

  const closeToast = () => {
    setToast({ open: false, message: "", severity: "" });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${URL}/hiw/${formData._id}`, formData);
      showToast("Data updated successfully!", "success");
      setIsUpdated(false);
      fetchData(); // Refresh the form with updated data
    } catch (error) {
      showToast(`Error updating data: ${error.message}`, "error");
    }
  };

  // Handle Quill field changes
  const handleQuillChange = (field, value) => {
    setFormData({ ...formData, [field]: value });

    // Enable the update button if there are changes
    const hasChanges = Object.keys(originalData).some(
      (key) => formData[key] !== originalData[key]
    );
    setIsUpdated(hasChanges);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        How It Works
      </Typography>

      {/* Snackbar Notification */}
      <Snackbar
        open={toast.open}
        autoHideDuration={3000}
        onClose={closeToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={closeToast}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>

      {/* Form */}
      <Card sx={{ marginBottom: 4 }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {[
                "dealsAndBonusCards",
                "feedAndStories",
                "shopping",
                "delivery",
                "provider",
                "flyer",
              ].map((field) => (
                <Grid item xs={12} key={field}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {field
                      .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
                      .replace(/^./, (str) => str.toUpperCase())}
                  </Typography>
                  <ReactQuill
                    value={formData[field] || ""}
                    onChange={(value) => handleQuillChange(field, value)}
                    theme="snow"
                  />
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isUpdated} // Disable button if no changes
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default HowItWorksManager;
